<template>
  <section id="clients">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 text-center">
          <h1 class="wow fadeInUp" data-wow-duration="2s">
            Leamington Vet Clinic Staff
          </h1>
          <hr class="center" />
        </div>
      </div>
      <div class="row client-container">
        <div class="col-md-6 col-sm-12 wow pulse" data-wow-duration="1s">
          <div class="client-box" style="margin-top: 20px">
            <div class="client-image">
              <img src="@/assets/images/dr-hussein.jpg" alt="Client" />
            </div>
            <div class="client-text">
              <h4>
                <span> Dr. Hussain - DVM/Owner </span>
              </h4>
              <p>
                Our Erie Veterinarian Dr Hussain decided to come to beautiful
                Leamington for the many opportunities to work and raise his
                family. He came from Chatham after working for the past few
                years at Merritt Animal Hospital along with fellow partner Dr.
                Kerry Mall.
              </p>
              <p>
                Dr.Hussain is incredibly excited to open his new vet clinic in
                Leamington. He has a great deal of passion for veterinary
                medicine and is waiting for the opportunity to utilize his
                skills and show his compassion to the furry pets that enter
                through the doors.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 wow pulse" data-wow-duration="1s">
          <div class="client-box" style="margin-bottom: 20px">
            <div class="client-image">
              <img src="@/assets/images/krista-mall.jpg" alt="Client" />
            </div>
            <div class="client-text">
              <h4>
                <span> Krista Mall - Practice Manager </span>
              </h4>
              <p>
                Krista joined the team with Dr. Hussain and Dr Kerry Mall to
                manage the new practice in Leamington. Being a Practice Manager
                is second nature to her as being the manager of her and Dr
                Mall's three vet clinics in Chatham-Kent for the past 5 years.
                She will make sure that all the day to day activities and behind
                the scenes work runs smoothly.
              </p>
            </div>
          </div>

          <div class="client-box" style="margin-bottom: 20px">
            <div class="client-image">
              <img src="@/assets/images/jessica-archibald.jpg" alt="Client" />
            </div>
            <div class="client-text">
              <h4>
                <span> Jessica Archibald - RVT </span>
              </h4>
              <p>
                Jessica is a recent graduate of Veterinary Technology program at
                St.Clair College in Windsor. She is coming to work as a
                full-time technician as well as performing front end work. She
                is very happy to be apart of the new team.
              </p>
            </div>
          </div>

          <!--Alicia-->
          <div class="client-box" style="margin-bottom: 20px">
            <div class="client-image">
              <img src="@/assets/images/alicia-allen.jpg" alt="Client" />
            </div>
            <div class="client-text">
              <h4>
                <span>
                  Alicia Allen - VOA & Certified Veterinary Assistant
                </span>
              </h4>
              <p>
                Alicia joined the Erie team to utilize her great receptionist
                skills as well as her exceptional skills in the back alongside
                the vet and technician. We are very familiar with Alicia as she
                is also a part of our sister clinic, Merritt Animal Hospital in
                Chatham. Alicia has been using her amazing qualities to the
                fullest and will welcome you with a friendly, big smile when you
                come into our doors at Erie. Alicia's passion towards animals is
                second to none, you can tell that she absolutely loves her job
                in working with animals every day.
              </p>
            </div>
          </div>
          <!--End Alicia-->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ClinicStaff",
};
</script>

<style>
@import "@/assets/css/bootstrap.min.css";
@import "@/assets/css/font-awesome.min.css";
@import "@/assets/css/styles.css";
@import "@/assets/css/loader.css";
</style>
