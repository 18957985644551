<template>
  <header id="header" class="">
    <span class="header-left"></span>
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12 logo-container">
          <div class="logo">
            <span class="for-logo"></span>
            <a href="index.html">
              <img
                class="logo-image img-responsive"
                src="@/assets/images/leamington-veterinarian.png"
                alt="Leamington Veterinarian"
              />
            </a>
          </div>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-8 col-xs-12 top-info-container">
          <div class="top-info">
            <div class="col-md-3 col-sm-5 address">
              <span>
                <i class="fa fa-map-marker"></i>
                102 Talbot St. W Leamington
              </span>
            </div>
            <div class="col-md-2 col-sm-3 phoneNo">
              <span>
                <i class="fa fa-phone"></i>
                519-322-2223
              </span>
            </div>
            <div class="col-md-3 col-sm-4 emailAddress">
              <span>
                <i class="fa fa-envelope"></i>
                <a
                  href="m&#97;ilto&#58;&#101;rievet&#104;osp&#37;6&#57;&#116;al&#64;ya%68&#111;&#37;6F&#46;ca"
                  >erie&#118;et&#104;ospit&#97;l&#64;ya&#104;o&#111;&#46;&#99;a</a
                >
              </span>
            </div>
            <div class="col-md-3 hidden-sm hidden-xs timing-info">
              <span>
                <i class="fa fa-facebook"></i>
                <a
                  target="_blank"
                  href="https://www.facebook.com/pages/Erie-Veterinary-Hospital-Leamington-Ontario/467461146754871"
                  >On Facebook
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "GlobalHeader",
};
</script>

<style>
@import "@/assets/css/bootstrap.min.css";
@import "@/assets/css/font-awesome.min.css";
@import "@/assets/css/styles.css";
@import "@/assets/css/loader.css";
</style>
