<template>
  <footer id="footer">
    <div class="container">
      <div class="row footer-info">
        <div class="col-md-5 col-sm-5 wow fadeIn" data-wow-duration="1s">
          <h3>Follow Us</h3>
          <div
            class="fb-page"
            data-href="https://www.facebook.com/pages/Erie-Veterinary-Hospital-Leamington-Ontario/467461146754871"
            data-hide-cover="false"
            data-show-facepile="true"
            data-show-posts="false"
          >
            <div class="fb-xfbml-parse-ignore">
              <blockquote
                cite="https://www.facebook.com/DresdenVeterinaryClinic"
              >
                <a href="https://www.facebook.com/erievet"
                  >Erie Veterinary Hospital</a
                >
              </blockquote>
            </div>
          </div>
        </div>
        <div
          class="col-md-4 col-sm-4 wow fadeIn"
          data-wow-delay="0.3s"
          data-wow-duration="1s"
        >
          <h3>Hours</h3>
          <div class="bottom-services">
            <p>Monday-Thursday: 8:30am-6:00pm</p>
            <p>Friday: 8:30am-5:30pm</p>
            <p>Saturday: 9:00am-1:00pm</p>
            <p>Sunday: Closed</p>
          </div>
        </div>
        <div
          class="col-md-3 col-sm-3 col-xs-12 wow fadeIn"
          data-wow-delay="0.6s"
          data-wow-duration="1s"
        >
          <h3>Get in touch</h3>
          <div class="inTouch">
            <div class="row inTouch-row">
              <div class="col-xs-1 inTouch-icon">
                <i class="fa fa-home"></i>
              </div>
              <div class="col-xs-11 inTouch-text">
                <p>
                  Erie Veterinary Hospital<br />
                  Leamington Veterinarian<br />
                  102 Talbot St. W <br />
                  Leamington, ON N8H 1M8
                </p>
              </div>
            </div>
            <div class="row inTouch-row">
              <div class="col-xs-1 inTouch-icon">
                <i class="fa fa-phone-square"></i>
              </div>
              <div class="col-xs-11 inTouch-text">
                <p>519-322-2223</p>
              </div>
            </div>
            <div class="row inTouch-row">
              <div class="col-xs-1 inTouch-icon">
                <i class="fa fa-envelope"></i>
              </div>
              <div class="col-xs-11 inTouch-text">
                <p>
                  <a
                    href="m&#97;ilto&#58;&#101;rievet&#104;osp&#37;6&#57;&#116;al&#64;ya%68&#111;&#37;6F&#46;ca"
                    >erie&#118;et&#104;ospit&#97;l&#64;ya&#104;o&#111;&#46;&#99;a</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row copy-right text-center">
        <p>Copyright 2015 - Erie Veterinary Hospital All Rights Reserved.</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "GlobalFooter",
};
</script>

<style>
@import "@/assets/css/bootstrap.min.css";
@import "@/assets/css/font-awesome.min.css";
@import "@/assets/css/styles.css";
@import "@/assets/css/loader.css";
</style>
