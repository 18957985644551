<template>
  <section id="services" class="module parallax parallax-2">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 text-center">
          <h1 class="wow fadeInUp" data-wow-duration="2s">
            Our Veterinary Services
          </h1>
          <hr class="center" />
        </div>
      </div>
      <div class="row services-container">
        <div
          class="col-lg-4 col-md-4 col-sm-4 wow pulse"
          data-wow-duration="1s"
        >
          <div class="service-detail">
            <img
              src="@/assets/images/pet-surgery.jpg"
              class="img-responsive"
              alt="image-1"
            />
            <div class="service-hover">
              <h3>Pet Surgical Services</h3>
              <p>
                Our veterinarian Dr. Hussain has many years of experience
                conducting animal surgeries. Surgical services are performed
                on-site for your convenience.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-4 col-sm-4 wow pulse"
          data-wow-duration="1s"
        >
          <div class="service-detail">
            <img
              src="@/assets/images/digital-xray.jpg"
              class="img-responsive"
              alt="image-1"
            />
            <div class="service-hover">
              <h3>Digital X-Ray</h3>
              <p>
                Digital X-Ray uses less radiation and is perfect for detecting
                dental problems or tracking the healing process of a broken
                bone.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-4 col-sm-4 wow pulse"
          data-wow-duration="1s"
        >
          <div class="service-detail">
            <img
              src="@/assets/images/animal-dental-care.jpg"
              class="img-responsive"
              alt="image-1"
            />
            <div class="service-hover">
              <h3>Pet Dental Care</h3>
              <p>
                Diseased gums and oral cavity can easily spread infections
                through the bloodstream directly to your pet's vital organs.
                It's important to keep your pet's teeth healthy.
              </p>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 text-center">
            <h2 class="wow fadeInUp" data-wow-duration="2s">
              And Many More...
            </h2>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "VeterinaryServices",
};
</script>

<style>
@import "@/assets/css/bootstrap.min.css";
@import "@/assets/css/font-awesome.min.css";
@import "@/assets/css/styles.css";
@import "@/assets/css/loader.css";
</style>
