<template>
  <global-header msg="Welcome to Erie Veterinary Hospital" />
  <!-- Welcome -->
  <div id="welcome" style="border-style: outset">
    <img src="@/assets/images/staffpic.jpg" alt="Staff" class="stretchy" />
  </div>
  <!-- Welcome -->

  <about-page />
  <veterinary-services />
  <clinic-staff />
  <section id="map" class="google-maps">
    <iframe
      style="pointer-events: none; border: 0"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2962.600579420846!2d-82.6048015!3d42.05174459999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883ac0e20603e1e5%3A0xa1423f9ccb555233!2s102+Talbot+St+W%2C+Leamington%2C+ON+N8H+1M8%2C+Canada!5e0!3m2!1sen!2sus!4v1436456628378"
      width="800"
      height="600"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </section>

  <global-footer />
</template>

<script>
import GlobalHeader from "./components/GlobalHeader.vue";
import GlobalFooter from "./components/GlobalFooter.vue";
import ClinicStaff from "./components/ClinicStaff.vue";
import VeterinaryServices from "./components/VeterinaryServices.vue";
import AboutPage from "./components/AboutPage.vue";

export default {
  name: "App",
  components: {
    GlobalHeader,
    GlobalFooter,
    ClinicStaff,
    VeterinaryServices,
    AboutPage,
  },
};
</script>
