<template>
  <section id="about">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 text-center">
          <hr class="center" />
          <p class="section-text wow fadeInUp" data-wow-duration="1s">
            At Erie Veterinary Hospital, we view your pet's health as our top
            priority and friendly, professional service is our goal. We bring
            the very best in animal medical care, and our veterinary hospital
            provides a full range of general, surgical and specialized care
            services.
          </p>
        </div>
      </div>
      <div class="row section-detail">
        <div
          class="col-lg-5 col-md-6 col-sm-6 detail wow pulse"
          data-wow-duration="1s"
        >
          <img :src="this.currentSlide" alt="Staff" class="img-responsive" />
          <h3 style="margin-top: 10px">Tour Our Facility!</h3>
        </div>
        <div
          class="col-lg-7 col-md-6 col-sm-6 detail wow pulse"
          data-wow-duration="1s"
        >
          <div class="col-lg-2 col-md-2 col-sm-2 col-xs-3">
            <i class="fa fa-paw" style="font-size: 54px; color: #db1c23"></i>
          </div>
          <div class="col-lg-10 col-md-10 col-sm-10 col-xs-9">
            <h3>New Clients are Welcome!</h3>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutPage",
  data() {
    return {
      slides: [],
      currentSlide: null,
      currentIndex: 0,
    };
  },
  mounted() {
    this.slides = [
      require("@/assets/images/revslider/leamvet/a1.jpg"),
      require("@/assets/images/revslider/leamvet/a2.jpg"),
      require("@/assets/images/revslider/leamvet/a3.jpg"),
      require("@/assets/images/revslider/leamvet/a4.jpg"),
      require("@/assets/images/revslider/leamvet/a5.jpg"),
      require("@/assets/images/revslider/leamvet/a6.jpg"),
      require("@/assets/images/revslider/leamvet/a7.jpg"),
      require("@/assets/images/revslider/leamvet/a8.jpg"),
      require("@/assets/images/revslider/leamvet/a9.jpg"),
      require("@/assets/images/revslider/leamvet/a10.jpg"),
      require("@/assets/images/revslider/leamvet/a11.jpg"),
      require("@/assets/images/revslider/leamvet/a12.jpg"),
      require("@/assets/images/revslider/leamvet/a13.jpg"),
      require("@/assets/images/revslider/leamvet/a14.jpg"),
      require("@/assets/images/revslider/leamvet/a15.jpg"),
      require("@/assets/images/revslider/leamvet/a16.jpg"),
      require("@/assets/images/revslider/leamvet/a17.jpg"),
      require("@/assets/images/revslider/leamvet/a18.jpg"),
    ];
    this.currentSlide = this.slides[this.currentIndex];
    setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.slides.length;
      this.currentSlide = this.slides[this.currentIndex];
    }, 3000);
  },
  components: {},
};
</script>

<style scoped>
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>
